import React from "react";
import { Helmet } from "react-helmet";
import ProjectsBody from "../components/projects/ProjectsBody";
import PageHero from "../components/reusables/hero/PageHero";

export default function Projects() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.JMSTONELOGISTICS.com/projects"
        />
        <meta
          name="description"
          content="JMSTONE Logistics is an earthworks construction company and leading supplier and installer of Geosynthetics, HDPEs and Institutional Uniforms"
        />
        <meta
          property="og:description"
          content="JMSTONE Logistics is an earthworks construction company and leading supplier and installer of Geosynthetics, HDPEs and Institutional Uniforms"
        />
        <meta
          property="og:title"
          content="Projects Highlights - JMSTONE LOGISTICS"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1708492028/jmstonebanner_hoycxz.jpg"
        />

        <meta
          name="twitter:title"
          content="Projects Highlights - JMSTONE LOGISTICS"
        />
        <meta
          name="twitter:text:title"
          content="Projects Highlights - JMSTONE LOGISTICS"
        />
        <meta
          name="twitter:description"
          content="JMSTONE Logistics is an earthworks construction company and leading supplier and installer of Geosynthetics, HDPEs and Institutional Uniforms"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1708492028/jmstonebanner_hoycxz.jpg"
        />
        <title>Projects Highlights - JMSTONE LOGISTICS</title>
      </Helmet>
      <PageHero
        classes="doggy-bg"
        titleClasses="title uppercase dark text-shadow"
        title="PROJECTs HIGHLIGHTS"
      />
      <ProjectsBody />
    </>
  );
}
