import React from "react";
import { Slide } from "react-reveal";
import ClientCard from "../reusables/ClientCard";

const Clientelle = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 center margin-auto">
              <p className="section-name boldTitle dark">
                PARTNERS &amp; CLIENTS
              </p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title light-blue">OUR CLIENTELLE</h2>
                </div>
              </Slide>
            </div>
            <div className="section-body">
              <ul className="clients-container section-spacing-20">
                <ClientCard client="MOTA-ENGIL Uganda  " />
                <ClientCard client="Uganda Ministry of Defense (Uganda HBP Oil and Gas Limited)" />
                <ClientCard client="EnviroServe Waste Management (Uganda) Ltd" />
                <ClientCard client="SA. Field Uganda Limited " />
                <ClientCard client="EcoServe Uganda Limited " />
                <ClientCard client="EPSILON " />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clientelle;
