import React from "react";
import { Helmet } from "react-helmet";
import AboutHome from "../components/home/AboutHome";
import Clientelle from "../components/home/Clientelle";
import CoreMarkets from "../components/home/CoreMarkets";
import HomeHero from "../components/home/HomeHero";
import OurWorks from "../components/home/OurWork";
import WhyJM from "../components/home/WhyJM";

export default function Home() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.jmstonelogistics.com" />
      </Helmet>
      <HomeHero />
      <WhyJM />
      <AboutHome />
      <CoreMarkets />
      <OurWorks/>
      <Clientelle />
    </>
  );
}
