import React from "react";
import { Slide } from "react-reveal";
import Strengthcard from "../reusables/StrengthCard";

const WhyJM = () => {
  return (
    <section className="section section-spacing-100 strength-pull">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600">
              <p className="section-name boldTitle light-blue">
                VALUES &amp; STRENGTHS
              </p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">WHY JMSTONE?</h2>
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <ul className="strengths-cards-container">
                <Slide top>
                  <Strengthcard
                    caption="We believe in"
                    strength="Integrity, Excellence, Commitment &amp; Innovation"
                  />
                </Slide>
                <Slide bottom>
                  <Strengthcard
                    caption="our ethics"
                    strength="Safe and Secure On Time &amp; Budget Delivery"
                  />
                </Slide>
                <Slide top>
                  <Strengthcard
                    caption="our workforce"
                    strength="Experinced, Well trained and in enough numbers"
                  />
                </Slide>
                <Slide bottom>
                  <Strengthcard
                    caption="experience &amp; success"
                    strength="over 50 years with a distinctive success rate"
                  />
                </Slide>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJM;
