import React from "react";

const ProjectCard = (props) => {
  return (
    <li className="project-card">
      <figure>
        <img src={props.img} className="box-shadow" alt={props.caption} />
      </figure>
      <figcaption>{props.caption}</figcaption>
    </li>
  );
};

export default ProjectCard;
