import React from "react";
import { Slide } from "react-reveal";
import about1 from "../../assets/images/content/jm16.png";
import about2 from "../../assets/images/content/jm6.jpg";
import about3 from "../../assets/images/content/jm20.jpeg";

const AboutHome = () => {
  return (
    <section className="section section-spacing-20 no-padding-bottom cloudy-bg">
      <div className="container">
        <div className="large">
          <div className="section-content section-spacing-100 no-padding-top">
            <div className="about-contents relative flex">
              <Slide left>
                <div className="about-image-side images-side flex">
                  <img src={about1} className="box-shadow" alt="About Image" />
                  <img src={about2} className="box-shadow" alt="About Image" />
                  <img
                    src={about3}
                    alt="About Image"
                    className="about-big-img box-shadow"
                  />
                </div>
              </Slide>
              <div className="about-text-side">
                <p className="section-name boldTitle dark">ABOUT US</p>
                <Slide top>
                  <div className="section-head">
                    <h2 className="section-title light-blue">
                      EXCELLENCE IN CONSTRUCTION
                    </h2>
                    <p className="section-subtitle margin-top-bottom-10">
                      JMSTONE LOGISTICS LIMITED was established in 2021 to
                      render construction services in civil engineering,
                      mechanical engineering, electrical engineering, and
                      building construction. This was after the many years of
                      individual engagement of the founders in rendering such
                      services until they decided to incoporate and execute
                      clients' works as a company.
                    </p>

                    <p>
                      Lately, we've been more focused in the oil and gas sector
                      and earth works constructions in excavations, dams etc.
                      Today, we are the leading supplier and installer of
                      Geosynthetics, HDPEs and Institutional uniforms.
                    </p>
                    <p>
                      Our competitiveness is derived from our deep understanding
                      of our customers’ needs and our non –compromising stand on
                      delivering quality products and services, in a safe and
                      environmentally conscious approach.
                    </p>
                    <p>
                      Our commitment to innovation and excellence invariably
                      results in a successfully completed projects for both
                      contractors and clients. We understand and promote the
                      idea of working in a partnership with our clients to
                      ensure their goals are met.
                    </p>
                  </div>
                </Slide>
                <div className="section-footer buttons-container flex justify-center section-spacing-20 no-padding-bottom">
                  <a
                    href="/about-us"
                    className="button sub-cta boldTitle dark uppercase"
                  >
                    Read More &#8594;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
