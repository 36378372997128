import React from "react";

const Strengthcard = (props) => {
  return (
    <li className="strength-card">
      <span>{props.caption}</span>
      <h3>{props.strength}</h3>
    </li>
  );
};

export default Strengthcard;
