import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound404 from "./pages/PageNotFound404";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FloatingButton from "./floatingbutton/FloatingButton";
import Projects from "./pages/Projects";
import Policies from "./pages/Policies";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <FloatingButton />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/about-us">
                <About />
              </Route>
              <Route exact path="/projects">
                <Projects />
              </Route>
              <Route exact path="/contact-us">
                <Contact />
              </Route>
              <Route exact path="/policies">
                <Policies />
              </Route>
              <Route exact path="/404">
                <PageNotFound404 />
              </Route>
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    );
  }
}
