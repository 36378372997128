import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carousel1 } from "../reusables/carousel1";
import star from "../../assets/images/icons/star.svg";

const HomeHero = () => {
  return (
    <section className="hero dark-bg">
      <div className="section-content-container">
        <div className="hero-slide carousel relative">
          <div className="slider-items-container center-slide-arrows">
            <Slider {...carousel1}>
              <div className="image-container curtain curtain1">
                <p>Weilding of HDPE at Recovery Area, Buliisa</p>
              </div>
              <div className="image-container curtain curtain2">
                <p>Mombasa Petroleum Refinery under Africa Oil</p>
              </div>
              <div className="image-container curtain curtain3">
                <p>Geomebrane Repair, Ngara well site, Buliisa</p>
              </div>
              <div className="image-container curtain curtain4">
                <p>Polyliner works, Central processing facility, Buliisa</p>
              </div>
              <div className="image-container curtain curtain5">
                <p>HDPE Works, Kibali Gold mine, DRC</p>
              </div>
            </Slider>
          </div>

          <div className="container">
            <div className="large">
              <div className="hero-content-container center centered">
                <div className=" centered-inner">
                  <div className="best-section uppercase">
                    <p className="best-text">EARTHWORKS construction company</p>
                    <div className="best-bars-container flex">
                      <div className="best-bar"></div>
                      <img src={star} className="best-star" />
                      <div className="best-bar"></div>
                    </div>
                  </div>
                  <div className="brand-section uppercase">
                    <h2 className="best-text">jmstone</h2>
                  </div>
                  <div className="slogan-section">
                    <p className="best-text">
                      LEADING SUPPLIER &amp; INSTALLER OF GEOSYNTHETICS, HDPEs
                      AND INSTITUTIONAL UNIFORMS
                    </p>
                  </div>
                  <div className="cta-section flex uppercase">
                    <a
                      target="_blank"
                      href="/projects"
                      className="button sub-cta boldTitle white"
                    >
                      Our Portfolio &#8594;
                    </a>
                    <a
                      target="_blank"
                      href="/contact-us"
                      className="button main-cta boldTitle pale-blue-bg dark"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
