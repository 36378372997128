import React from "react";
import logo from "../../assets/images/logo/logo.png";
import fb from "../../assets/images/icons/facebook.svg";
import twt from "../../assets/images/icons/twitter.svg";
import wa from "../../assets/images/icons/whatsapp.svg";

const Footer = () => {
  return (
    <section className="footer section-spacing-20 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-50">
              <div className="footer-menu-sections-container">
                <div className="footer-menu-section">
                  <h3>company</h3>
                  <ul>
                    <li>
                      <a href="/policies">policies</a>
                    </li>
                    <li>
                      <a href="/about-us">about us</a>
                    </li>
                    <li>
                      <a href="/contact-us">contact us</a>
                    </li>
                    <li>
                      <a href="/projects">projects highlights</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-section">
                  <h3>core markets</h3>
                  <ul>
                    <li>
                      <a href="/projects">oil &amp; gas</a>
                    </li>
                    <li>
                      <a href="/projects">Environmental Restoration Works</a>
                    </li>
                    <li>
                      <a href="/projects">Waste Disposal Pits Construction</a>
                    </li>
                    <li>
                      <a href="/projects">
                        HDPE Liner welding and Installation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-section">
                  <h3>Construction services</h3>
                  <ul>
                    <li>
                      <a href="#">civil engineering</a>
                    </li>
                    <li>
                      <a href="#">mechanical engineering</a>
                    </li>
                    <li>
                      <a href="#">electrical engineering</a>
                    </li>
                    <li>
                      <a href="#">building construction</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-section">
                  <h3>Core values</h3>
                  <ul>
                    <li>
                      <a href="#">integrity</a>
                    </li>
                    <li>
                      <a href="#">commitment</a>
                    </li>
                    <li>
                      <a href="#">excellence</a>
                    </li>
                    <li>
                      <a href="#">innovation</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-about">
                <div className="footer-brand flex">
                  <img src={logo} alt="JMSTONE LOGO" />
                  <div>
                    <p>Nantabulirwa, Goma Division</p>
                    <p>P.O Box 150154, Mukono, Uganda</p>
                    <p>+256 770 882 236 | info@jmstonelogistics.com</p>
                  </div>
                </div>
                <div className="footer-copyright">
                  <p className="flex">
                    <a href="#">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="#">
                      <img src={twt} alt="Twitter" />
                    </a>
                    <a href="#">
                      <img src={wa} alt="WhatsApp" />
                    </a>
                  </p>
                  <p>&copy; 2024 JMSTONE LOGISTICS LIMITED,</p>
                  <p>All Rights Reserved.</p>
                </div>
              </div>
            </div>
            <div className="designer section-spacing-10 uppercase">
              <div className="design-inner white center">
                <p className=" rich-small">
                  Website by{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ntuna.com"
                    className="orange"
                  >
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
