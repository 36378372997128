import React from "react";
import { Slide } from "react-reveal";
import Mission from "../reusables/Mission";

const AboutBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom cloudy-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="textpage-container">
              <div className="textpage-inner section-spacing-50 no-padding-top flex">
                <div className="textpage-text-side">
                  <h2 className=" light-blue">JMSTONE LOGISTICS LIMITED</h2>
                  <div className="textpage-text-body">
                    <p>
                      JMSTONE LOGISTICS LIMITED was established in 2021 to
                      render construction services in civil engineering,
                      mechanical engineering, electrical engineering, and
                      building construction. This was after the many years of
                      individual engagement of the founders in rendering such
                      services until they decided to incoporate and execute
                      clients' works as a company.
                    </p>
                    <p>
                      Lately, we've been more focused in the oil and gas sector
                      and earth works constructions in excavations, dams etc.
                      Today, we are the leading supplier and installer of
                      Geosynthetics, HDPEs and Institutional uniforms.
                    </p>
                    <p>
                      Our competitiveness is derived from our deep understanding
                      of our customers’ needs and our non –compromising stand on
                      delivering quality products and services, in a safe and
                      environmentally conscious approach.
                    </p>
                    <p>
                      Our commitment to innovation and excellence invariably
                      results in a successfully completed projects for both
                      contractors and clients. We understand and promote the
                      idea of working in a partnership with our clients to
                      ensure their goals are met.
                    </p>
                    <h3>Our Vision</h3>
                    <p>
                      “To become the leading provider of quality civil
                      engineering and Construction services of choice in Uganda
                      and East Africa”
                    </p>
                    <h3>Our Mission</h3>
                    <p>
                      “We exist to deliver excellent construction and
                      engineering services, safely, to our customers using
                      committed and innovative resources while registering
                      optimum returns on our investment.”
                    </p>

                    <h3>Location &amp; Contacts</h3>
                    <p>
                      We are located in Nantabulirwa, Goma Division, Mukono
                      District, Uganda. You can always give us a call or
                      WhastApp on +256 (706) 383 011 or +256 (770) 882 236 or
                      send us an email info@jmstonelogistics.com.
                    </p>
                  </div>
                  <div className="textpage-text-model boldTitle uppercase">
                    <p>
                      JMSTONE LOGISTICS LIMITED, an earthworks construction
                      company focused in the oil and gas, excavations, mines and
                      dams, and also a leading supplier and installer of
                      Geosynthetics, HDPEs and Institutional Uniforms.
                    </p>
                  </div>
                </div>
                <Mission />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
