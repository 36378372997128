import React from "react";

const HighlightCard = (props) => {
  return (
    <li className="highlight-card">
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <img src={props.img} alt="" className="highlight-img" />
        <p>{props.title}</p>
      </a>
    </li>
  );
};

export default HighlightCard;
