import React from "react";
import p1 from "../../assets/images/content/p1.jpeg";
import p2 from "../../assets/images/content/p2.jpeg";
import p3 from "../../assets/images/content/p3.jpeg";
import p4 from "../../assets/images/content/p4.jpeg";
import p5 from "../../assets/images/content/p5.jpeg";
import p6 from "../../assets/images/content/p6.jpeg";
import p7 from "../../assets/images/content/p7.jpeg";
import p8 from "../../assets/images/content/p8.jpeg";
import e1 from "../../assets/images/content/e1.jpeg";
import e12 from "../../assets/images/content/e12.jpeg";
import e4 from "../../assets/images/content/e4.jpeg";
import e7 from "../../assets/images/content/e7.jpeg";
import e11 from "../../assets/images/content/e11.jpeg";
import e9 from "../../assets/images/content/e9.jpeg";
import jm2 from "../../assets/images/content/jm2.jpg";
import jm8 from "../../assets/images/content/jm8.jpg";
import j211 from "../../assets/images/content/j211.jpg";
import h2 from "../../assets/images/content/h2.jpeg";
import h7 from "../../assets/images/content/hh1.jpeg";
import h1 from "../../assets/images/content/h1.jpeg";
import ProjectCard from "../reusables/ProjectCard";

const ProjectsBody = () => {
  return (
    <section className="section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="section-body section-spacing-20">
                <ul className="projects-container">
                  <ProjectCard
                    img={p3}
                    caption="Line repair, Kingfisher Oil waste management facility in Kyangwali, Kikuube District."
                  />
                  <ProjectCard
                    img={p8}
                    caption="Leachate collection pit, Kingfisher Oil waste management facility in Kyangwali, Kikuube District."
                  />
                  <ProjectCard
                    img={p1}
                    caption="Lining of tyre wash train pit, Kingfisher Oil waste management facility in Kyangwali, Kikuube District."
                  />
                  <ProjectCard
                    img={p2}
                    caption="Storm water evaporation pit, Luwero Industries Limited (Kingfisher Oil waste management facility)"
                  />
                  <ProjectCard
                    img={p7}
                    caption="HDPE Weilding, Luwero Industries Limited (Kingfisher Oil waste management facility)"
                  />
                  <ProjectCard
                    img={p5}
                    caption="Leachate collection pit, Kingfisher Oil waste management facility in Kyangwali, Kikuube District."
                  />
                  <ProjectCard
                    img={e1}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={e4}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={e7}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={e9}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={e11}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={e12}
                    caption="Environmental Restoration, Kyangwali Luweero Industries, Waste management facility"
                  />
                  <ProjectCard
                    img={jm2}
                    caption="Mombasa Petroleum Refinery under Africa Oil"
                  />
                  <ProjectCard
                    img={jm8}
                    caption="Geomebrane Repair, Ngara well site, Buliisa"
                  />
                  <ProjectCard
                    img={j211}
                    caption="Exacavation works, Waste Pit Construction"
                  />
                  <ProjectCard img={h1} caption="Men of uniform at Work" />
                  <ProjectCard img={h2} caption="Men of uniform at Work" />
                  <ProjectCard img={h7} caption="Men of uniform at Work" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsBody;
