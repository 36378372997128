import React from "react";
import { Slide } from "react-reveal";
import w1 from "../../assets/images/content/h7.jpeg";
import w2 from "../../assets/images/content/jm2.jpg";
import w3 from "../../assets/images/content/jm7.jpg";
import HighlightCard from "../reusables/HighlightCard";

const OurWorks = () => {
  return (
    <section className="section projects section-spacing-100 pale-blue-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600">
              <p className="section-name boldTitle light-blue">
                PREVIOUS PROJECTS
              </p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">Some of Our Works</h2>
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <Slide left>
                <ul className="hightlights-container flex">
                  <HighlightCard
                    img={w1}
                    link="#"
                    title="Weilding of HDPE at Recovery Area, Buliisa"
                  />
                  <HighlightCard
                    img={w2}
                    link="#"
                    title="Mombasa Petroleum Refinery under Africa Oil"
                  />
                  <HighlightCard
                    img={w3}
                    link="#"
                    title="Geomebrane Repair, Ngara well site, Buliisa"
                  />
                </ul>
              </Slide>
            </div>
            <div className="section-footer buttons-container flex justify-center section-spacing-20 no-padding-bottom">
              <a href="/projects" className="button sub-cta boldTitle dark uppercase">
                See More &#8594;
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurWorks;
