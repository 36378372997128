import React from "react";

const ClientCard = (props) => {
  return (
    <li className="client-card">
      <p>{props.client}</p>
    </li>
  );
};

export default ClientCard;
