import React from "react";
import pp from "../../assets/files/privacy-policy.pdf";

const PoliciesBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content width-960 margin-auto">
            <div className="policies-container flex">
              <div className="policy-card">
                <h2>PRIVACY POLICY</h2>
                <p>This is our Privacy Policy, Click "Download" to download</p>
                <a
                  href={pp}
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PoliciesBody;
