import React from 'react';
import { Slide } from "react-reveal";
import pitconst from "../../assets/images/content/j21.jpg";
import liner from "../../assets/images/content/h6.jpeg";
import restor from "../../assets/images/content/e1.jpeg";
import HighlightCard from "../reusables/HighlightCard";

const CoreMarkets = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600">
              <p className="section-name boldTitle light-blue">
                OIL, MINES &amp; GAS
              </p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">MARKET SPECIALIZATION</h2>
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <Slide left>
                <ul className="hightlights-container flex">
                  <HighlightCard
                    img={pitconst}
                    link="/projects"
                    title="Waste Disposal Pits Construction"
                  />
                  <HighlightCard
                    img={liner}
                    link="/projects"
                    title="HDPE Liner Weilding &amp; Installation"
                  />
                  <HighlightCard
                    img={restor}
                    link="/projects"
                    title="Environmental Restoration Works"
                  />
                </ul>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreMarkets;
